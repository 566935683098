import axios from "../js/ajax";
// 获取运营数据
export const getOperationList = params =>
   axios.get("/electricity/lease/data/operation/list",{
      params,
      loading:true
   });

// // 导出用户
// export const leadOut = params =>{
//   axios.get("/")
// }


// 收益统计
export const getIncomeStatistics = params =>
   axios.get("/electricity/lease/data/operation/income/statistics",{params})
// 资金方首页大屏
export const getfunderHomepage = params =>
   axios.get("/electricity/lease/data/funderHomepage",{params})
// 资金方地图
export const getHomepageMap = params =>
   axios.get("/electricity/lease/data/funderHomepage/map",{params});
// 收益汇总
export const getCollect = params =>
   axios.get("/electricity/lease/data/operation/income/collect",{params});
// 定位时间
export const getPositionTime = params =>
   axios.get("/electricity/lease/data/operation/location/collect",{params});
// 运营大屏租电
export const getStatistics = params =>
   axios.get("/electricity/lease/data/operation/time/statistics",{params});
   
// 运营大屏租电echarts
export const getStatisticsChart = params =>
   axios.get("/electricity/lease/data/operation/time/chart",{params});
// 状态统计
export const getStatus = params =>
   axios.get("/electricity/lease/data/state/statistics",{params});
// 告警统计
export const warnStatus = params =>
   axios.get("/electricity/lease/data/alarm/statistics",{params});
// 运营导出
export const dataStatementExport = params =>
   axios({
      url:"/electricity/lease/data/operation/list/export",
      method:'GET',
      params:params
   });
// 导出文件(单独)
export const queryExportTask = params =>
   axios.get("/electricity/lease/data/queryExportTask",{params});
//运营数据导出
export const operationExport = params =>
   axios.get("/electricity/lease/data/operation/export",{params});
//  //导出列表
// export const listExport = path =>
// axios.get(path);


   