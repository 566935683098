<template>
  <el-button :loading="loading" v-bind="$attrs" type="primary" @click="exportOrder" :disabled="disabled">{{btnText}}</el-button>
</template>

<script>
import {timeToDate} from '@/utils/date-format'
export default {
  props: {
    btnText: {
      default: '导出excel',
      // type: String,
    },
    title: {
      default: "测试"
    },
    tHeader: {
      type: Array,
      default: () => ['订单号','类型', '经纪人ID', '经纪人手机号', '收货人姓名', '收货人手机号', '详细地址', '申领时间', '快递单号', '快递公司','状态'] // 导出的表头名
    },
    filterVal: {
      type: Array,
      default: () => ['order_id', 'type_text','user_id', 'mobile', 'user_name', 'contract_phone', 'address', 'gmt_created', 'express_no', 'express_name','status_text'] // 导出的表头字段名
    },
    request: {
      
    },
    multiHeader: {
      default: () => ['运营数据报告']
    },
    multiHeader2: {
      default: () => []
    },
    form:{
      type:Object
    },
    unitNos:{
      type:Array
    },
    
  },
  data() {
    return {
      loading:false,
      disabled: false,
    }
  },
  methods: {
    exportOrder() {
      this.$confirm('此操作将导出excel文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then( async () => {
        this.loading = true;
       const date = new Date().getTime();
       const time = timeToDate(date);
       this.title = `数据运营报告${time}`
        this.disabled = true;
        let timeObj = {
          beginIndex : 0,
            pageSize:10000
        };
        const params = {
          ...this.form,
          ...timeObj
          };
        const res = await this.request(params);
        this.disabled = false;
        this.excelData = res.ldata // 你要导出的数据list。
        this.export2Excel();
      }).catch((e) => {
        console.log(e, 555);
      })
    },
    export2Excel() {
      var that = this
      require.ensure([], () => {
        // eslint-disable-next-line camelcase
        const {export_json_to_excel} = require('../excel/Export2Excel') // 这里必须使用绝对路径
        const tHeader = this.tHeader;
        const filterVal = this.filterVal;
        const list = that.excelData
        const data = that.formatJson(filterVal, list)
        // export_json_to_excel(tHeader, data, this.title)// 导出的表格名称，根据需要自己命名
        this.loading = false;
        // export_json_to_excel(tHeader, data, this.title, this.multiHeader, this.multiHeader2)// 导出的表格名称，根据需要自己命名
        export_json_to_excel({
          multiHeader: this.multiHeader,
          multiHeader2: this.multiHeader2,
          header: tHeader,
          data,
          merges: [],
          filename: this.title,
        })// 导出的表格名称，根据需要自己命名
        this.$message.success('导出成功');
      })
    },
    formatJson(filterVal, jsonData) {
      const arr = jsonData.map(v => filterVal.map(j => v[j]))
      return arr;

    },

  },
  components: {

  }
}
</script>

<style scoped>

</style>
