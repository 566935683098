<template>
  <div>
    <header class="dfc">
      <el-form ref="form" :model="form" label-width="100px" :inline="true">
        <el-form-item label="代理商：">
          <AgentTree
          :props="props"
          v-model="unitNos"
          @agentList='getAgentList'
          style="width: 300px"
        ></AgentTree>
        </el-form-item>
        <!--  -->
        <el-form-item label="创建时间：">
          <el-date-picker
            class="ml32"
            v-model="timeArr"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          />
        </el-form-item>
        <el-button type="primary" class="ml32" @click="onSearh">搜索</el-button>
        <el-button type="primary" @click="importBtn">导出</el-button>
        <!-- <exportBtn
          class="ml32"
          :tHeader='exportTtlte'
          :filterVal='exportData'
          :request='request'
          :title='title'
          :multiHeader2='multiHeader2'
          :unitNos='unitNos'
          :form="form"
          /> -->
      </el-form>
    </header>
    <section>
      <el-table :data="tableData" style="width: 100%" >
        <el-table-column prop="unitName" label="代理商" width="180"/>
        <el-table-column prop="storeName" label="门店" />
        <el-table-column prop="specification" label="商品规格" width="180"/>
        <el-table-column prop="newDevice" label="设备净新增数"/>
        <el-table-column prop="newUser" label="新增用户数"/>
        <el-table-column prop="returnUser" label="退还用户数"/>
        <el-table-column prop="increaseUser" label="净增用户数"/>
        <el-table-column prop="renewUser" label="续费用户数"/>
        <el-table-column prop="deviceStock" label="设备库存（实时）"/>
        <el-table-column prop="useDevice" label="使用中设备（实时）"/>
        <el-table-column prop="leaveDevice" label="闲置设备数（实时）"/>
        <el-table-column prop="leavePercent" label="闲置率（实时）">
          <template slot-scope="{ row }">
             <span>{{row.leavePercent}}%</span>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <footer >
      <el-pagination
        @current-change="currentChange"
        :current-page.sync="page"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        :total="total"
      />
    </footer>
  </div>
</template>

<script>
import { getOperationList,getIncomeStatistics,dataStatementExport,operationExport} from "@/api/dataStatement";
import  AgentTree from '@/components/AgentTree';
import  exportBtn from '@/components/exportBtn';

export default {
  data() {
    return {
      selectKey: '',
      props: {
        value: "unitNo",
        label: "name",
        checkStrictly: true
      },
      unitNos:[],
      form: {
        unitNo: "",
        beginTime: null,
        endTime:null,
      },
      timeArr:[],//时间
      total:1,
      OperationList:[],
      selectValue: [],
      tableData:[],
      exportTtlte: ['代理商名称', '商品规格', '设备净新增数', '新增用户数', '退还用户数', '净增用户数', '续费用户数', '设备库存', '使用中设备', '闲置设备数', '闲置率'],
      exportData: ['unitName', 'specification', 'newDevice', 'newUser', 'returnUser', 'increaseUser', 'renewUser', 'deviceStock', 'useDevice','leaveDevice', 'leavePercent'],
      params: {
        pageSize: 20,
        beginIndex: 0,
      },
      title: '数据运营报告',
      page: 1,
      pageSize: 20,
      request: getOperationList,
      multiHeader2: [],
      agentList: []
    };
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  mounted() {
    const _this = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.onSearh()
      }
    };
    this.getOperationList();
  },
  computed: {
    beginIndex() {
      return this.page - 1
    },
  },
  methods: {
    // 导出
    async importBtn(){
       if(this.timeArr && this.timeArr.length > 0){
          this.form.beginTime = new Date(this.timeArr[0]).getTime();
          this.form.endTime = new Date(this.timeArr[1]).getTime();
        }else{
          this.form.beginTime = null;
          this.form.endTime = null;
        }
        if(this.unitNos && this.unitNos.length >0){
          this.form.unitNo = this.unitNos[this.unitNos.length - 1];
        }else{
          this.form.unitNo = null;
        }
      try{
        const params = {...this.form};
        const res = await operationExport(params);
        if(+res.result === 1){
          this.$router.push('/leaseholdMgmt/exportTask');
        }

      }catch(e){

      }
    },
    onSearh() {
      this.page = 1;
      this.getOperationList()
    },
    // 搜索按钮
    async getOperationList() {
      this.form.unitNo = this.unitNos[this.unitNos.length - 1];
      try {
        if(this.timeArr && this.timeArr.length != 0){
          this.form.beginTime = new Date(this.timeArr[0]).getTime();
          this.form.endTime = new Date(this.timeArr[1]).getTime();
        }else{
          this.form.beginTime = null;
          this.form.endTime = null;
        }
        const params = {...this.form};
        params.pageSize = this.pageSize
        params.beginIndex = this.beginIndex
        /** 处理导出 */
        if ((this.unitNos && this.unitNos.length > 0) || (this.timeArr && this.timeArr.length)) {
          this.params = params
          this.multiHeader2= ['查询条件：']
          if(this.timeArr && this.timeArr.length) {
            const arr = ['导出时间范围', this.timeArr[0],  this.timeArr[1]]
            this.multiHeader2 = [...this.multiHeader2, ...arr]
          }
          if (this.unitNos && this.unitNos.length == 1) {
            const info = this.agentList.find(el => el.unitNo == this.unitNos[0])
            const arr = ['代理商', info.name]
            this.multiHeader2 =  [...this.multiHeader2, ...arr]
          } else if (this.unitNos && this.unitNos.length == 2) {
            const info = this.agentList.find(el => el.unitNo == this.unitNos[0])
            const info2 = info.subUnit.find(el => el.unitNo == this.unitNos[1])
            const arr = ['门店', info2.name]
            this.multiHeader2 =  [...this.multiHeader2, ...arr]
          }
        } else {
           this.multiHeader2 = []
        }
        const res = await getOperationList(params);
        this.total = res.mdata.total;
        this.tableData = res.ldata;
      } catch (error) {
        console.log(error);
      }
    },
    // 点击页面
    currentChange(page) {
      this.page = page
      this.getOperationList();
    },
    getAgentList(agentList) {
      this.agentList = agentList
    }

  },
  components: {
    AgentTree,
    exportBtn
  }
};
</script>

<style scoped lang="less">
  .el-table{
    margin-bottom: 30px;
  }
  footer {
     display: flex;
     justify-content: flex-end;
  }
  .el-pagination{

  }
</style>
